<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <!--<div><router-link to="/Admin/xueyxj/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>-->
                    <!-- <div><el-button icon="el-icon-search" @click="exports()">导出表格</el-button></div>-->
                    <!--<div><el-button icon="el-icon-search" ><router-link to="/Admin/xueyxj/forms">导入表格</router-link></el-button></div>-->
                </div>


                <div class="admin_main_block_left">
                    <div style="width:150px;"><el-input   v-model.trim="where.F_VR_SHOUJH" placeholder="手机号"></el-input></div>
                    <!--<div style="width:150px;"><el-input type="number"  v-model.trim="where.F_VR_LIANXFS" placeholder="联系方式"></el-input></div>
                    <div style="width:140px;">
                        <el-select v-model="where.F_IN_ZSLS_ID" placeholder="请选择招生老师" @change="selectChange()">
                            <el-option label="请选择招生老师" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['zsls']" :label="v.F_VR_XINGM" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </div>-->

                    <div style="width:410px;">
                        <el-date-picker
                                size="small"
                                v-model="where.times"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :default-time="['12:00:00']"
                        >
                        </el-date-picker>
                    </div>


                    <!--<div style="width:160px;">
                        <el-select v-model="where.F_TI_SHIFYJZT" placeholder="选择是否系统一级">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="是" value="1"></el-option>
                            <el-option label="否" value="2"></el-option>
                        </el-select>
                    </div>-->


                    <div style=""><el-button icon="el-icon-search" @click="get_adv_list()">条件筛选</el-button></div>

                </div>


                <!--<div class="admin_main_block_right">
                    <div >

                        <el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button>
                    </div>
                </div>-->



            </div>
            <div class="admin_table_main">
                <el-table :data="info" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
                    <el-table-column prop="F_IN_ID" label="#"  width="70px"></el-table-column>
                    <!--<el-table-column prop="F_VR_XINGM" label="姓名" width="70px">
                        <template slot-scope="scope">
                            <el-tag type="danger" v-if="scope.row.chongfcount==1">男</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.chongfcount==2">女</el-tag>
                        </template>
                    </el-table-column>-->

                    <el-table-column label="昵称" width="100px">
                        <template slot-scope="scope">
                            <div  closable="true" >{{ scope.row.F_VR_NIC }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="头像" width="100px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><img :src="scope.row.F_VR_TOUX||require('@/assets/default_pic.png')" width="50px" height="50px"></dt>
                                <dd class="table_dl_dd_all">{{ scope.row.adv_title }}</dd>
                            </dl>
                        </template>
                    </el-table-column>

                    <el-table-column label="姓名" width="100px">
                        <template slot-scope="scope">
                            <div  closable="true" >{{ scope.row.F_VR_XINGM }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="手机号" >
                        <template slot-scope="scope">
                            <div  closable="true" >{{ scope.row.F_VR_SHOUJH }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="微信收款码" align="center" width="185px">
                        <template slot-scope="scope">
                            <el-popover placement="left" trigger="click" width="600">
                                <img :src="scope.row.F_VR_WEIXSKM" width="100%" />
                                <img
                                        slot="reference"
                                        :src="scope.row.F_VR_WEIXSKM"
                                        :alt="scope.row.F_VR_WEIXSKM"
                                        style="max-height: 70px;max-width: 70px; padding: 5px"
                                />
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="支付宝收款吗" align="center" width="185px">
                        <template slot-scope="scope">
                            <el-popover placement="left" trigger="click" width="600">
                                <img :src="scope.row.F_VR_ZHIFBSKM" width="100%" />
                                <img
                                        slot="reference"
                                        :src="scope.row.F_VR_ZHIFBSKM"
                                        :alt="scope.row.F_VR_ZHIFBSKM"
                                        style="max-height: 70px;max-width: 70px; padding: 5px"
                                />
                            </el-popover>
                        </template>
                    </el-table-column>




                    <!--<el-table-column label="性别" >
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.gender==1">男</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.gender==2">女</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="province" label="省" ></el-table-column>
                    <el-table-column prop="city" label="市" ></el-table-column>-->


                    <!--<el-table-column prop="num" label="下级数量" ></el-table-column>-


                    <el-table-column label="是否已发安慰奖" width="160px">
                        <template slot-scope="scope">
                            <div :class="scope.row.F_TI_SHIFYFFAWJ==1?'green_round':'gray_round'" @click="xitzt_status(scope.row.F_IN_ID)"></div>
                        </template>
                    </el-table-column>-->

                    <!--<el-table-column label="是否直推一级" >
                        <template slot-scope="scope">
                            <div  v-if="scope.row.F_TI_SHIFYQJR==1">是</div>
                            <div  v-if="scope.row.F_TI_SHIFYQJR==2">否</div>
                        </template>
                    </el-table-column>-->

                    <!--<el-table-column prop="F_VR_ZSLS_XINGM" label="明星老师"></el-table-column>-->


                    <el-table-column prop="F_IN_CHUANGJSJ" label="创建时间">
                        <template slot-scope="scope">
                            <div v-if="scope.row.F_IN_CHUANGJSJ<=0"> - </div>
                            <div v-else>{{scope.row.F_IN_CHUANGJSJ|formatDate}}</div>
                        </template>
                    </el-table-column>

                    <!--<el-table-column label="操作" fixed="right" width="120px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'xueyxj_form',params:{id:scope.row.F_IN_ID}})">编辑</el-button>
                            &lt;!&ndash; <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> &ndash;&gt;
                        </template>
                    </el-table-column>-->
                    <div style="margin: 0px" >{!! info->links() !!}</div>
                    <div slot="append">
                        <div class='sum_footer xiaoji' ref='sum_xiaoji'>

                            <!--<div class='sum_footer_unit center' >已付定金：{{dingj}}</div>
                            <div class='sum_footer_unit center' >已付尾款：{{weik}}</div>-->

                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit center' >会员人数：{{yiwcrs}}</div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                        </div>
                        <!--<div class='sum_footer' ref='sum_heji'>
                            <div class='sum_footer_unit center'>合计</div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'>6</div>
                            <div class='sum_footer_unit'>6</div>
                            <div class='sum_footer_unit'>6</div>
                        </div>-->
                    </div>
                </el-table>

                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                list:[],
                total_data:0, // 总条数
                page_size:20,
                current_page:1,
                select_id:'',
                adv_position_id:0,
                where:{
                    times:[],
                    F_VR_SHOUJH:'',
                },
                info:[],
                zongje:0,
                dingj:0,
                weik:0,
                yiwcrs:0
            };
        },
        watch: {},
        computed: {},
        methods: {
            xitzt_status:function(id){
                this.$post(this.$api.fafXueyxjStatus,{id:id}).then(res=>{
                    if(res.code==200){
                        this.$message.success('操作成功');
                    }else{
                        this.$message.success('操作失败');
                    }
                    this.get_adv_list();
                });
            },
            handleSelectionChange:function(e){
                let ids = [];
                e.forEach(v => {
                    ids.push(v.F_IN_ID);
                });
                this.select_id = ids.join(',');
            },
            get_adv_list:function(){
                /*this.where.page = this.current_page;*/
                let where = {};
                where.page = this.current_page;
                where.params = this.where;
                this.$get(this.$api.getYijdlList,where).then(res=>{
                    this.page_size = res.data.info.per_page;
                    this.total_data = res.data.info.total;
                    this.current_page = res.data.info.current_page;
                    this.list = res.data;
                    this.info = res.data.info.data;
                    this.zongje = res.data.zongje;
                    this.dingj = res.data.dingj;
                    this.weik = res.data.weik;
                    this.yiwcrs = res.data.yiwcrs;
                })
            },
            // 删除处理
            del:function(F_IN_ID){
                if(this.$isEmpty(F_IN_ID)){
                    return this.$message.error('请先选择删除的对象');
                }
                this.$post(this.$api.delXueyxj,{F_IN_ID:F_IN_ID}).then(res=>{
                    if(res.code == 200){
                        this.get_adv_list();
                        return this.$message.success("删除成功");
                    }else{
                        return this.$message.error("删除失败");
                    }
                });
            },
            current_change:function(e){
                this.current_page = e;
                this.get_adv_list();
            },
        },
        created() {
            this.get_adv_list();
        }
    };
</script>
<style lang="scss" scoped>
    .sum_footer{
        display:flex;
        display:-webkit-flex;
        line-height:50px;
        color:#606266;
    }
    .sum_footer_unit{
        flex-grow:1;
        -webkit-flex-grow:1;
        text-indent:10px;
        font-size:14px;
        font-weight:bold;
    }
    .sum_footer_unit.center{
        text-align:center;
    }
    .sum_footer.xiaoji{
        border-bottom:1px solid #EBEEF5;
    }

    .admin_main_block_left div {
        float: left;
        margin-right: 2px;
    }

    .el-range-editor--small .el-range-input {
        font-size: 13px;
        width: 120px;
    }

</style>